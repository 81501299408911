'use client'

import { useCallback, useMemo } from 'react'
import { useAnalyticsSetup } from '~/components/hooks/useAnalytics'
import type { Analytics } from '~/lib/analytics'
import { PersonalizationProvider } from '~/components/providers/PersonalizationProvider/PersonalizationProvider'
import BraQuiz from '~/components/cms/legacy/BraQuiz/BraQuiz'
import CoreMediaPlacements from '~/components/cms/legacy/CoreMediaPlacements'
import RewardsEnroller from '~/components/pages/Landing/Rewards/RewardsEnroller'
import { pageHasFeaturedProducts } from '~/lib/client-server/cms/sources/coremedia/shared/featured-products'
import type { CategoryLandingProps } from '~/lib/types/pageprops.interface'
import { getPathSegmentsAfterDescriptors } from '~/lib/utils'
import useNavigation from '~/components/hooks/useNavigation'
import { pageHasHotspots } from '~/lib/client-server/cms/sources/coremedia/shared/hotspots'
import {
	pageHasContentCarousel,
	pageHasArticles,
	pageHasStoryPlayer,
} from '~/lib/client-server/cms/sources/coremedia/shared/collection'
import Breadcrumbs from '~/components/shared/Breadcrumbs'
import styles from './CategoryLanding.module.scss'

const SEO_BLOG_BREADCRUMBS_ID = 'seo-blog-breadcrumbs-id'

export default function CategoryLanding({ cacheData, pageContent, breadcrumbs }: CategoryLandingProps) {
	const { asPath } = useNavigation()
	const urlSegments = getPathSegmentsAfterDescriptors(asPath)
	const hasHotspots = pageHasHotspots(pageContent)
	const hasFeaturedProducts = useMemo(() => pageHasFeaturedProducts(pageContent), [pageContent])
	const hasContentCarousel = pageContent ? pageHasContentCarousel(pageContent) : false
	const hasArticles = pageHasArticles(pageContent)
	const hasStoryPlayer = pageHasStoryPlayer(pageContent)

	const isBlogPage = breadcrumbs && breadcrumbs[0].id?.toLowerCase() === 'playbooks'

	const beforePageView = useCallback(
		(analytics: Analytics) => {
			analytics.setPageData({
				products: [],
				page_name: urlSegments.join('|'),
				page_category: urlSegments[0] || undefined,
				page_subcategory1: urlSegments[1] || undefined,
				page_subcategory2: urlSegments[2] || undefined,
				page_subcategory3: urlSegments[3] || undefined,
				page_subcategory4: urlSegments[4] || undefined,
				grid_has_guidedselling: false,
				grid_paging_offset: 0, // TODO: This needs to be a number (not a cursor)
				grid_single_ingrid: 0,
				grid_stack_count: 0,
				grid_double_ingrid: 0,
				grid_video_count: 0,
				grid_top_content: '',
				grid_has_loadmore: false,
				grid_sort_order: '',
				grid_total_count: 0,
				grid_visible_count: 0,
				grid_refinement_attributes: [],
				page_finding_method: '',
				featured_products: {
					state: hasFeaturedProducts ? 'viewed' : null,
				},
				hotspots: {
					state: hasHotspots ? 'viewed' : null,
				},
				content_carousel: {
					state: hasContentCarousel ? 'loaded' : null,
				},
				storyplayer: {
					state: hasStoryPlayer ? 'loaded' : null,
				},
			})
		},
		[urlSegments, hasFeaturedProducts, hasHotspots, hasContentCarousel, hasStoryPlayer],
	)

	useAnalyticsSetup(
		{
			allowPageView: true,
			page_name: '', // this has to get set dynamically
			pageCategory: [], // this has to get set dynamically
			page_type: 'content',
			site_section: 'Category Landing Page',
			beforePageView,
		},
		[urlSegments],
	)

	// Variable created because there are more instances where CoreMedia Placements need to be rendered than not
	const showCoreMediaPlacements = pageContent?.categoryId !== 'sports-bra-sizing-guide'

	return (
		<>
			{pageContent?.categoryId === 'sports-bra-sizing-guide' && <BraQuiz pageContent={pageContent} />}
			{pageContent?.categoryId === 'rewards' && <RewardsEnroller />}
			{showCoreMediaPlacements && (
				<PersonalizationProvider>
					{isBlogPage && (
						<div className={styles.nav__wrapper} data-article-page={hasArticles}>
							<Breadcrumbs
								id={SEO_BLOG_BREADCRUMBS_ID}
								currentPath={asPath}
								trail={breadcrumbs}
								getCurrentCrumbBy="position"
								lastWithoutLink
								className={styles.breadcrumbs}
							/>
						</div>
					)}
					<CoreMediaPlacements cacheData={cacheData} pageContent={pageContent} className={styles.sectionWrapper} />
				</PersonalizationProvider>
			)}
		</>
	)
}
