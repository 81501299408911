'use client'

import React, { memo, useEffect, useRef, useId } from 'react'
import { exposeRefTo } from '~/components/actions'
import { ensureString } from '~/types/strict-null-helpers'

type IgnoredAttrs = Extract<keyof React.ComponentPropsWithRef<'input'>, 'hidden' | 'aria-hidden'>
export interface FormFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, IgnoredAttrs> {
	/** The text label that appears above the input, and acts like a placeholder in the realm of how our UA Design System works. If we apply a label here, we should NOT add a placeholder prop. */
	label: string | React.ReactNode
	/** Will not render any elements that deal with the display of invalid data */
	noValidate?: boolean
	/** Elements that will be rendered within the containing div with the ability to be positioned within the input */
	children?: React.ReactNode
	/** Text that overrides dynamic error messages, and puts the component into an error state if a value exists. Will only display if noValidate is false */
	errorText?: string | React.ReactNode
	/** If true - we show a floating label experience */
	floatingLabel?: boolean
	/** Element that will be prefixed to the input element */
	prefixLabel?: React.ReactNode | string
}

export const InputField = memo(
	React.forwardRef<HTMLInputElement, FormFieldProps>(function FormField(
		{
			label,
			id,
			className = '',
			type = 'text',
			noValidate = false,
			errorText,
			floatingLabel = true,
			children,
			placeholder = ' ',
			prefixLabel,
			...rest
		},
		ref,
	) {
		const inputRef = useRef<HTMLInputElement>(null)
		const formRef = useRef<HTMLDivElement>(null)
		const prefixContainerRef = useRef<HTMLDivElement>(null)
		useEffect(() => {
			if (inputRef.current) {
				inputRef.current.value = ensureString((rest.defaultValue as string) ?? '')
			}
		}, [rest.defaultValue])

		useEffect(() => {
			function setPrefixContainerWidth() {
				formRef.current?.style.setProperty('--label-offset-prefix', `${prefixContainerRef.current?.offsetWidth}px`)
			}

			// When we have a prefix element, the placeholder needs to be situated to its left and animate to the focus position, this set the offset programatically to be natively picked up by the CSS transition.
			if (prefixLabel && prefixContainerRef && formRef) {
				setPrefixContainerWidth()
				// Sometimes offsetWidth is zero in production builds (and not dev, perhaps due to race condition), so retry setting width on next render cycle
				if (prefixContainerRef.current?.offsetWidth === 0) setTimeout(setPrefixContainerWidth, 0)
			}
		}, [prefixLabel, prefixContainerRef, formRef])

		const generatedId = useId()

		return (
			<div ref={formRef} className={`form-field ${floatingLabel ? 'floating-field' : ''}`}>
				{prefixLabel && (
					<div ref={prefixContainerRef} className="prefix-label-container">
						{prefixLabel}
					</div>
				)}
				<input
					ref={exposeRefTo(ref, inputRef)}
					type={type}
					id={id ?? generatedId}
					className={`${className.trim()}`}
					placeholder={floatingLabel && !placeholder ? ' ' : placeholder}
					aria-invalid={!!errorText}
					aria-describedby={noValidate ? undefined : `${id ?? generatedId}-error`}
					{...rest}
				/>
				{floatingLabel && (
					<label className="floating-label" htmlFor={id ?? generatedId}>
						{label ?? placeholder}
					</label>
				)}
				{!noValidate && (
					<div id={`${id ?? generatedId}-error`} role="alert">
						{errorText}
					</div>
				)}
				{children}
			</div>
		)
	}),
)
