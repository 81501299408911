'use client'

import type { FetchResult } from '@apollo/client'
import { gql, useMutation } from '@apollo/client'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import type { Mutation } from '~/graphql/generated/uacapi/type-document-node'

import { useCallback, useState } from 'react'
import { validateEmail } from '~/lib/validators'
import { useFormatMessage } from './useFormatMessage'

export type EmailSubscribeResult = Pick<Mutation, 'subscribeToNewsletter'>

const NEWSLETTER_SUBSCRIBE = gql`
	mutation addSubscriber($email: String!, $source: String!) {
		subscribeToNewsletter(input: { clientMutationId: $email, email: $email, sourceCode: $source }) {
			clientMutationId
			success
			msg
		}
	}
`

export interface EmailSubscribeHookResult {
	/** The async function to call to initiate a subscription request */
	addSubscriber: (email: string, source?: string) => Promise<FetchResult<EmailSubscribeResult> | undefined>

	/** Indicates that the subscription request is in process */
	loading: boolean

	/** Returned by the API after a successful request (not necessarily a successful response) */
	data?: EmailSubscribeResult | null

	/** The error returned either by the API or a generic error if the network request could not be made for some reason */
	apiError?: string | null

	/** Email validation error during the check that is done in the addSubscriber call */
	emailError?: string | null

	/** The message returned from the API when it is successful */
	successMsg?: string | null
}

/**
 * This hook returns a function that can be called to subscribe a user to our email list.  In addition to the
 * function it will return the following state variables:
 *  - loading: Indicates whether the subscription call is being made and has not returned yet
 *  - data: The data returned from the mutation request
 *  - apiError: The message that was either returned from the API when there was an error or a generic message when the call could not even be made
 *  - emailError: A message describing if there was an email validation error (this check happens when the call to the addSubscribe function is made)
 *  - successMsg: The message returned when the API returns successfully.
 * @param sourceCode The code that is used for firing analytics data when the subscription is successful
 * @param location The location code used for firing analytics data when the subscription is successful
 * @returns
 */
export function useEmailSubscribe(sourceCode: string, location: string): EmailSubscribeHookResult {
	const [apiError, setApiError] = useState<string | null>(null)
	const [emailError, setEmailError] = useState<string | null>(null)
	const [successMsg, setSuccessMsg] = useState<string | null>(null)

	const formatMessage = useFormatMessage()

	const { analyticsManager } = useAnalytics()

	// sending subscribe to newsletter mutation heref
	const [_addSubscriber, { data, loading }] = useMutation<EmailSubscribeResult>(NEWSLETTER_SUBSCRIBE)

	const addSubscriber = useCallback(
		async (emailAddress: string, source?: string): Promise<FetchResult<EmailSubscribeResult> | undefined> => {
			try {
				if (!emailAddress) {
					setEmailError(formatMessage('form-errors-required'))
				} else if (!validateEmail(emailAddress)) {
					setEmailError(formatMessage('email-error-invalid'))
				} else {
					setEmailError(null)
					setApiError(null)
					const result = await _addSubscriber({
						variables: {
							email: emailAddress,
							source: source || sourceCode,
						},
					})

					if (result.data) {
						if (result.data.subscribeToNewsletter?.success) {
							setSuccessMsg(result.data.subscribeToNewsletter?.msg || null)
							analyticsManager.fireEmailSubscribed({
								type: 'ua',
								plain_text_email: result.data.subscribeToNewsletter.clientMutationId || '',
								email_hashed: '', // we don't have access to this through uacapi
								opt_in_source: sourceCode,
								opt_in_location: location,
							})
						} else if (result.data.subscribeToNewsletter?.msg) {
							setApiError(result.data.subscribeToNewsletter?.msg)
						}
					}

					return result
				}
			} catch (err) {
				setApiError(formatMessage('email-error-server-issue'))
			}

			return undefined
		},
		[_addSubscriber, formatMessage, location, sourceCode, analyticsManager],
	)

	return { addSubscriber, data, loading, apiError, emailError, successMsg }
}
