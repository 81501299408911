import clsx from 'clsx'
import { z } from 'zod'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { actPostalCodeFormatted } from '~/components/actions'
import { useLoadingStatus } from '~/components/hooks'
import { useFormValidation, type ValidatedFormSubmit } from '~/components/hooks/useFormValidation'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import { useLoyalty } from '~/components/providers/LoyaltyProvider/LoyaltyProvider'
import { Button } from '@ua-digital-commerce/ua-web-components/components/Button'
import { InputField } from '~/components/primitives/InputField/InputField'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import { GreenCheckCircleIcon } from '~/components/primitives/icons'
import { getCountryCodeByLocale } from '~/lib/i18n/locale'
import { EnrollmentSteps } from './RewardsEnroller'
import styles from './RewardsEnrollerShared.module.scss'
import { getZodEmailSchema, getZodPostalSchema } from '~/lib/forms'

enum ZipCodeCheckSteps {
	ZipCodeForm,
	WaitListForm,
	WaitListed,
}

const WaitListConfirmationStep = () => {
	const formatMessage = useFormatMessage()

	return (
		<>
			<GreenCheckCircleIcon size="LG" />
			<div className={styles.header}>{formatMessage('youre-all-set')}</div>
			<div className={styles.copy}>{formatMessage('first-to-know')}</div>
			<div className={styles['action-buttons']}>
				<LocaleLink href={'/c/new-arrivals'}>
					<Button type="button">{formatMessage('shop-for-gear')}</Button>
				</LocaleLink>
			</div>
		</>
	)
}

interface WaitListStepProps {
	setCurrentZipStep: Dispatch<SetStateAction<ZipCodeCheckSteps>>
	postalCode: string
}

interface WaitListFormData {
	email: string
}

const WaitListStep = ({ postalCode, setCurrentZipStep }: WaitListStepProps) => {
	const formatMessage = useFormatMessage()
	const [error, setError] = useState(false)

	const { loyaltyOperationLoading: loading, loyaltyOperationError: joinLoyaltyError, joinWaitlist } = useLoyalty()

	const handleSubmit = useCallback(
		async (validatedSubmit: ValidatedFormSubmit<WaitListFormData>) => {
			const { validated, values } = validatedSubmit

			if (!validated) return

			const resp = await joinWaitlist({ email: values.email, postal: postalCode })
			if (resp) {
				setCurrentZipStep(ZipCodeCheckSteps.WaitListed)
			} else {
				setError(true)
			}
		},
		[postalCode, joinWaitlist, setCurrentZipStep],
	)

	const zodValidationSchema = useMemo(
		() =>
			z.object({
				email: getZodEmailSchema(formatMessage),
			}),
		[formatMessage],
	)

	const { bindFormValidation, handleFieldFocusout } = useFormValidation<WaitListFormData>({
		onSubmit: handleSubmit,
		scrollToError: false,
		validationSchema: zodValidationSchema,
	})

	const submitStatus = useLoadingStatus({ loading, error: error || !!joinLoyaltyError })

	return (
		<div className={styles['waitlist-entry']}>
			<div className={styles.header}>{formatMessage('some-things-are-worth-the-wait')}</div>
			<div className={styles.copy}>{formatMessage('rewards-not-launched-yet')}</div>
			<form ref={bindFormValidation} onBlur={handleFieldFocusout} className={styles['waitlist-form']}>
				<div className={clsx('form-field', styles['rewards-landing-input-wrapper'])}>
					<InputField
						id="email"
						data-testid="email-input"
						name="email"
						label={formatMessage('email-address')}
						className={styles['rewards-landing-input']}
						required
					/>
				</div>
				<div className={styles['action-buttons']}>
					<Button type="submit" status={submitStatus}>
						{formatMessage('join-waitlist')}
					</Button>
				</div>
			</form>
			{error && <div className={styles.error}>{formatMessage('loyalty-waitlist-error')}</div>}
		</div>
	)
}

interface ZipCodeStepProps {
	setCurrentStep: Dispatch<SetStateAction<EnrollmentSteps>>
	setCurrentZipStep: Dispatch<SetStateAction<ZipCodeCheckSteps>>
	setPostalCode: Dispatch<SetStateAction<string>>
	isLoyaltyPilotFlow: boolean
}

interface ZipCheckFormData {
	postalCode: string
}

const ZipCodeStep = ({ setCurrentStep, setPostalCode, setCurrentZipStep, isLoyaltyPilotFlow }: ZipCodeStepProps) => {
	const formatMessage = useFormatMessage()
	const locale = useLocale()
	const countryCode = getCountryCodeByLocale(locale)

	const { loyaltyOperationLoading: loading, loyaltyOperationError: error, isEligible } = useLoyalty()

	const handleSubmit = useCallback(
		async (validatedSubmit: ValidatedFormSubmit<ZipCheckFormData>) => {
			const { validated, values } = validatedSubmit

			if (!validated) return

			setPostalCode(values.postalCode)

			const eligible = await isEligible({ postal: values.postalCode, region: countryCode.toLocaleUpperCase() })

			if (isLoyaltyPilotFlow && eligible) {
				setCurrentStep(EnrollmentSteps.Available)
			} else {
				setCurrentZipStep(ZipCodeCheckSteps.WaitListForm)
			}
		},
		[setPostalCode, countryCode, isLoyaltyPilotFlow, isEligible, setCurrentStep, setCurrentZipStep],
	)
	const zodValidationSchema = useMemo(
		() =>
			z.object({
				postalCode: getZodPostalSchema(countryCode, formatMessage),
			}),
		[formatMessage, countryCode],
	)

	const { bindFormValidation, handleFieldFocusout } = useFormValidation<ZipCheckFormData>({
		onSubmit: handleSubmit,
		scrollToError: false,
		validationSchema: zodValidationSchema,
	})

	const submitStatus = useLoadingStatus({ loading, error: !!error })

	return (
		<>
			<div className={styles.callout}>{formatMessage('a-new-way-to-score-more')}</div>
			<div className={styles.header}>{formatMessage('introducing-ua-rewards')}</div>
			<div className={styles.copy}>{formatMessage('be-one-of-the-first')}</div>

			<form ref={bindFormValidation} onBlur={handleFieldFocusout} className={styles['zip-code-form']}>
				<div className={clsx('form-field', styles['rewards-landing-input-wrapper'])}>
					<InputField
						id="zip-code"
						name="postalCode"
						data-testid="zip-input"
						autoComplete="postal-code"
						className={styles['rewards-landing-input']}
						label={clsx(formatMessage('zip-code'), '*')}
						ref={actPostalCodeFormatted(countryCode)}
						required
					/>
				</div>
				<div className={styles['action-buttons']}>
					<Button type="submit" status={submitStatus}>
						{formatMessage('check-zip-code')}
					</Button>
				</div>
			</form>
		</>
	)
}

function RewardsEnrollerZipCheck({ setCurrentStep, isLoyaltyPilotFlow }) {
	const [currentZipStep, setCurrentZipStep] = useState<ZipCodeCheckSteps>(ZipCodeCheckSteps.ZipCodeForm)
	const [postalCode, setPostalCode] = useState<string>('')

	const zipCheckFlow = useMemo(() => {
		switch (currentZipStep) {
			case ZipCodeCheckSteps.WaitListForm:
				return <WaitListStep postalCode={postalCode} setCurrentZipStep={setCurrentZipStep} />
			case ZipCodeCheckSteps.WaitListed:
				return <WaitListConfirmationStep />
			default:
				return (
					<ZipCodeStep
						setCurrentStep={setCurrentStep}
						isLoyaltyPilotFlow={isLoyaltyPilotFlow}
						setCurrentZipStep={setCurrentZipStep}
						setPostalCode={setPostalCode}
					/>
				)
		}
	}, [currentZipStep, isLoyaltyPilotFlow, postalCode, setCurrentStep])

	return <>{zipCheckFlow}</>
}

export default RewardsEnrollerZipCheck
