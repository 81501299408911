export default {
	emailWebSourceCodesJSON: {
		appEmailSignupModel: 'appEmailSignupModel',
		checkout: 'checkout',
		emailSignupModel: 'emailSignupModel',
		footer: 'footer',
		mEmailSignupModel: 'mEmailSignupModel',
		registerEmailOptInForDesktop: 'registerEmailOptInForDesktop',
		registerEmailOptInForMobile: 'registerEmailOptInForMobile',
		US143: 'US143',
	},
	emailSignupLocations: {
		appEmailSignupModel: 'appEmailSignupModel',
		checkout: 'checkout',
		emailSignupModel: 'emailSignupModel',
		footer: 'footer',
		mEmailSignupModel: 'mEmailSignupModel',
		newVisitorModal: 'new-visitor-modal',
		register: 'register',
		registerEmailOptInForDesktop: 'registerEmailOptInForDesktop',
		registerEmailOptInForMobile: 'registerEmailOptInForMobile',
	},
}
