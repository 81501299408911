'use client'

/* eslint-disable local-rules/disallow-untranslated-literals-in-jsx */

import React, { Fragment, memo, useCallback, useState } from 'react'
import type { CmLinkable } from '~/graphql/generated/coremedia/type-document-node'
import { isCmCollection } from '~/lib/cms/legacy/type-guard'
import type { PageContentData } from '~/lib/types/coremedia.interface'
import { ensureArray, isNonNullish } from '~/types/strict-null-helpers'

import CoreMediaPlacements from '~/components/cms/legacy/CoreMediaPlacements'
import Image from '~/components/primitives/Image'
import ProgressBar from '~/components/primitives/ProgressBar/ProgressBar'
import type { BraQuizAnswer, BraQuizSection } from './BraQuizData'
import { braQuizConfig } from './BraQuizData'

import styles from './BraQuiz.module.scss'
import { Button } from '@ua-digital-commerce/ua-web-components/components/Button'

interface QuestionRoutingProps {
	dependent: string
	next_question: string
}

interface QuizAnswersProps {
	answer: BraQuizAnswer
	name: string
	dependent?: string | undefined
	questionRouting?: QuestionRoutingProps
}
function QuizAnswers({ answer, name, dependent, questionRouting }: QuizAnswersProps) {
	/** This will filter out results that are not related to the users input */
	if (dependent && dependent === questionRouting?.dependent && answer.id !== questionRouting?.next_question) return null
	return (
		<div className={styles.radiolist}>
			{answer.options.map((option) => (
				<Fragment key={option.id}>
					<input
						id={option.id}
						className="visually-hidden"
						name={name}
						type="radio"
						value={option.id}
						data-next-question={option?.next_question}
						data-result={option?.result}
						required
					/>
					<label className="text-body font-semibold" htmlFor={option.id}>
						{answer.inputType === 'list_image' && (
							<Fragment key={option.id}>
								<Image src={option.image} alt={option.alt} width={180} height={105} aria-hidden />
								<span>{option.label}</span>
							</Fragment>
						)}
						{answer.inputType === 'list' && option.label}
					</label>
				</Fragment>
			))}
		</div>
	)
}

function ErrorPage() {
	return (
		<div className={styles['bra-quiz-wrapper']}>
			<div className="text-h5">Not matches found.</div>
		</div>
	)
}

interface BraQuizProps {
	pageContent: PageContentData
}
function BraQuiz({ pageContent }: BraQuizProps) {
	const [step, setStep] = useState<BraQuizSection['step']>(0)
	const [questionRouting, setQuestionRouting] = useState<QuestionRoutingProps | undefined>()
	const [resultPage, setResultPage] = useState<string | null>(null)
	const [filteredPageContent, setFilteredPageContent] = useState<PageContentData | null>(null)

	/** Handler for going to the next (or previous) portion of the Bra Quiz */
	function handleStepChange(event: React.MouseEvent<HTMLButtonElement>) {
		const button = event.currentTarget
		const form = button.form as HTMLFormElement

		if (button.hasAttribute('data-step-next')) {
			const fieldset = form.children[step] as HTMLFieldSetElement
			const groups = fieldset.role === 'radiogroup' ? [fieldset] : Array.from(fieldset.querySelectorAll('fieldset'))

			groups.forEach((g) => {
				const radio = g.elements[0] as HTMLInputElement
				g.setAttribute('aria-invalid', String(radio.validity.valueMissing))
			})

			if (groups.some((g) => g.getAttribute('aria-invalid') === String(true))) return
			if (button.type === 'submit') return // Let the `submit` handle submission logic
		}

		const nextStep = (button.hasAttribute('data-step-back') ? step - 1 : step + 1) as typeof step
		setStep(nextStep)

		const nextFieldset = form.children[nextStep] as HTMLFieldSetElement
		const stepTitle = nextFieldset.firstChild as HTMLLegendElement

		// Focus the next title for Keyboard Users and Screen Reader Users. (Do this AFTER React re-renders)
		setTimeout(() => stepTitle.focus())
	}

	/** The `change` handler for ALL radio button groups */
	function handleChange(event: React.ChangeEvent<HTMLFormElement>) {
		const radio = event.nativeEvent.target as HTMLInputElement
		;(radio.closest('fieldset') as HTMLFieldSetElement).removeAttribute('aria-invalid')

		if (radio.hasAttribute('data-next-question')) {
			setQuestionRouting({
				dependent: radio.name,
				next_question: radio.getAttribute('data-next-question') || '',
			})
		}
		if (radio.hasAttribute('data-result')) {
			setResultPage(radio.getAttribute('data-result'))
		}
	}

	/**
	 * This function can hopefully be fully removed when the data starts coming from the CoreMedia Graph
	 * Right now, it is taking all the data on the Bra Quiz page in the `secondary` placement, and finding
	 * just the parts we need based on the name from the `result` in the `BraQuizData.ts`
	 *
	 * TODO: Currently the work around is to store the results as the secondary placement on the Bra Quiz
	 * page. This is filtering everything else out of that, then finding the results by matching the "name"
	 * of the module to show only that data
	 */
	const getFilteredResults = useCallback(
		(resultPage: string) => {
			const secondaryPlacement = pageContent.placements.filter((placement) => placement.name === 'secondary')
			const filteredPlacement = secondaryPlacement.map((placement) => {
				/**
				 * Filter through `items` to find the item that matches our result from the quiz.
				 * `items` are a native CoreMedia field with no additional processing, so it will return all
				 * collections, or teasers at the top level, not a flattened list like `uaMergedItems` would.
				 */
				const filteredItems = placement?.items.filter((item) => !!item && item.name === resultPage)

				/**
				 * Get the `id` for each item that is found. This is going to be used to look up the correct items
				 * in the `uaMergedItems` to return the results.
				 */
				const filteredItemIds = filteredItems.reduce<string[]>((acc, filteredItem) => {
					if (isCmCollection(filteredItem))
						return ensureArray(filteredItem?.teasableItems)
							.filter((item) => !!item && item.id)
							.map((i) => i && i.id)
							.filter(isNonNullish)
					return [...acc, filteredItem?.id]
				}, [])

				/**
				 * Create a new clone of the `uaMergedItems` field for us to look through for the answers.
				 * We do this by comparing the `id` from the `items` to the `id` in the `uaMergedItems`.
				 */
				const newMergedItems = filteredItemIds.reduce<CmLinkable[]>((acc, filteredId) => {
					const foundModule = placement?.uaMergedItems.find((module) => module.id === filteredId)
					if (foundModule) return [...acc, foundModule]
					return acc
				}, [])

				/** Return our modified placement */
				return { ...placement, uaMergedItems: newMergedItems }
			})
			/** Return our modified pageContent */
			return { ...pageContent, placements: filteredPlacement }
		},
		[pageContent],
	)

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()
		const form = event.currentTarget
		if (!form.checkValidity()) return // Error styling is handled by stepper logic
		// const data = Object.fromEntries(new FormData(event.currentTarget))

		if (resultPage) {
			const filteredResults = getFilteredResults(resultPage)
			if (!filteredPageContent) setFilteredPageContent(filteredResults)
		}
	}

	if (filteredPageContent) {
		if (!resultPage) return <ErrorPage />
		return <CoreMediaPlacements pageContent={filteredPageContent} />
	}

	return (
		<div className={styles['bra-quiz-wrapper']}>
			<ProgressBar
				className={styles['progress-bar']}
				step={step}
				totalSteps={4}
				svgProps={{ preserveAspectRatio: 'none' }}
			/>

			<form id={styles['bra-quiz']} aria-label="Bra Quiz" noValidate onChange={handleChange} onSubmit={handleSubmit}>
				{braQuizConfig.sections.map((section) => (
					<Fragment key={section.id}>
						{section.id === 'size' ? ( // size renders 2 questions on the same page, styled slightly differently than other options
							<fieldset data-current-step={step === section.step}>
								<legend className={styles['section-heading']} tabIndex={0}>
									{section.question}
								</legend>
								{!!section.description && (
									<div id={`${section.id}-description`} className="text-body" aria-hidden>
										{section.description}
									</div>
								)}

								{section.answers.map((answer) => (
									<fieldset
										className={`form-field ${styles[`${section.id}`]}`}
										role="radiogroup"
										aria-describedby={`${answer.id}-error`}
										key={answer.id}
									>
										<legend className="text-body font-bold">{answer.label}</legend>

										<QuizAnswers
											answer={answer}
											name={answer.id}
											dependent={section?.dependent}
											questionRouting={questionRouting}
										/>

										<div id={`${answer.id}-error`} role="alert">
											{section.error}
										</div>
									</fieldset>
								))}
							</fieldset>
						) : (
							<fieldset
								className={`form-field ${styles[`${section.id}`]}`}
								role="radiogroup"
								aria-describedby={`${section.id}-description ${section.id}-error`}
								data-current-step={step === section.step}
							>
								<legend className={styles['section-heading']} tabIndex={0}>
									{section.question}
								</legend>
								{!!section.description && (
									<div id={`${section.id}-description`} className="text-body" aria-hidden>
										{section.description}
									</div>
								)}

								{section.answers.map((answer, index) => (
									<QuizAnswers
										answer={answer}
										name={section.id}
										dependent={section?.dependent}
										questionRouting={questionRouting}
										key={index}
									/>
								))}

								<div id={`${section.id}-error`} role="alert">
									{section.error}
								</div>
							</fieldset>
						)}
					</Fragment>
				))}

				<div>
					<Button
						className="font-semibold"
						secondary
						hidden={!step}
						aria-controls={styles['bra-quiz']}
						data-step-back
						onClick={handleStepChange}
					>
						Back
					</Button>

					<Button
						className="font-semibold"
						type={step === 2 ? 'submit' : 'button'}
						aria-controls={styles['bra-quiz']}
						data-step-next
						onClick={handleStepChange}
					>
						{step === 2 ? 'View Results' : 'Next'}
					</Button>
				</div>
			</form>
		</div>
	)
}

export default memo(BraQuiz)
