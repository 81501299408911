import styles from './ProgressBar.module.scss'

interface ProgressBarProps {
	step: number
	totalSteps: number
	className?: string
	svgProps?: React.SVGProps<SVGSVGElement>
}

function ProgressBar({ step, totalSteps, className, svgProps }: ProgressBarProps) {
	const width = ((step + 1) / totalSteps) * 100
	const classes = className ? `${className} ${styles['progress-bar']}` : styles['progress-bar']

	return (
		<div className={classes}>
			<svg width="100%" height="8px" viewBox="0 0 500 8" aria-hidden {...svgProps}>
				<rect x="0" y="0" width="100%" height="100%" fill="var(--color-grey-3)" />
				<rect x="0" y="0" width={`${width}%`} height="100%" rx="4" fill="var(--color-green)" />
			</svg>
		</div>
	)
}

export default ProgressBar
