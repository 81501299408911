'use client'

import { Suspense, useMemo, useState } from 'react'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { UARewardsNoBrackets } from '~/components/primitives/icons'
import { ensureBoolean } from '~/types/strict-null-helpers'
import { RewardsEnrollerAuthentication } from './RewardsEnrollerAuthentication'
import styles from './RewardsEnrollerShared.module.scss'
import RewardsEnrollerZipCheck from './RewardsEnrollerZipCheck'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'

export enum EnrollmentSteps {
	ZipCode,
	Available,
}

const RewardsEnroller = () => {
	const { isLoyaltyEnabled, isLoyaltyPilotEnabled } = useFeatureFlags() || {}
	const { isMobile } = useLayoutMeasurement()
	const logoDimensions = isMobile ? { width: 244, height: 24 } : { width: 400, height: 40 }
	const isLoyaltyPilotFlow = ensureBoolean(isLoyaltyEnabled && isLoyaltyPilotEnabled)

	const [currentStep, setCurrentStep] = useState<EnrollmentSteps>(
		isLoyaltyPilotFlow ? EnrollmentSteps.ZipCode : EnrollmentSteps.Available,
	)

	const currentStepComponent = useMemo(() => {
		switch (currentStep) {
			case EnrollmentSteps.Available:
				return (
					<Suspense>
						<RewardsEnrollerAuthentication />
					</Suspense>
				)
			case EnrollmentSteps.ZipCode:
			default:
				return <RewardsEnrollerZipCheck setCurrentStep={setCurrentStep} isLoyaltyPilotFlow={isLoyaltyPilotFlow} />
		}
	}, [currentStep, setCurrentStep, isLoyaltyPilotFlow])

	return (
		<div className={styles['rewards-enroller-container']}>
			<div className={styles['ua-icon']}>
				<UARewardsNoBrackets width={logoDimensions.width} height={logoDimensions.height} />
			</div>
			{currentStepComponent}
		</div>
	)
}

export default RewardsEnroller
