import { scene7RecipeBuilder } from '~/lib/scene7-recipes'

interface ListImage extends StandardOption {
	image: string
	alt: ''
}
interface StandardOption {
	id: string
	label: string
	next_question?: string // dictates flow to next programmed questions
	result?: string // result page url
}
export interface BraQuizAnswer {
	id: string
	label: string
	inputType: 'list' | 'list_image' // corresponds with style needs for each fieldset
	options: StandardOption[] | ListImage[] // groupElements = CoreMedia
}

export interface BraQuizSection {
	id: string
	question: string // name = CoreMedia
	description: string // hint = CoreMedia
	step: 0 | 1 | 2 // not accounted for in CoreMedia but sets what group of questions is shown during the quiz, this field could be used from the "progressValue"
	error: string // not accounted for in CoreMedia
	dependent?: string // this must equal the id of the section it is dependent on
	answers: BraQuizAnswer[]
}
interface BraQuizConfig {
	id: string
	sections: BraQuizSection[]
}

export enum BraQuizResult {
	CrossbackMid = 'BraQuiz_CrossbackMid_collection',
	HGArmourHigh = 'BraQuiz_HGArmourHigh_collection',
	InfinityLow = 'BraQuiz_InfinityLow_collection',
	InfinityMid = 'BraQuiz_InfinityMid_collection',
	InfinityHigh = 'BraQuiz_InfinityHigh_collection',
	SeamlessLow = 'BraQuiz_SeamlessLow_collection',
	SmartformMid = 'BraQuiz_SmartformMid_collection',
}

export const braQuizConfig: BraQuizConfig = {
	id: 'quiz_bra',
	sections: [
		{
			id: 'training',
			question: 'What type of training are you into?',
			description:
				"Choose the one you do—or plan to do—most often. Don't worry, you can always come back and take it again for another activity.",
			step: 0,
			error: 'Please select an option.',
			answers: [
				{
					id: '',
					label: '',
					inputType: 'list_image',
					options: [
						{
							id: 'q1_training_running',
							label: 'Running, Court Sports, Field Sports',
							next_question: 'training_running',
							image: scene7RecipeBuilder('FW23_TRN_Womens_Bra_Finder_QuestionImagery_HighSupport', 180, 106),
							alt: '',
						},
						{
							id: 'q1_training_cycling',
							label: 'Cycling, Boxing, Weight Lifting',
							next_question: 'training_cycling',
							image: scene7RecipeBuilder('FW23_TRN_Womens_Bra_Finder_QuestionImagery_MidSupport', 180, 106),
							alt: '',
						},
						{
							id: 'q1_training_yoga',
							label: 'Yoga, Hiking, Barre',
							next_question: 'training_yoga',
							image: scene7RecipeBuilder('FW23_TRN_Womens_Bra_Finder_QuestionImagery_LowSupport', 180, 106),
							alt: '',
						},
					],
				},
			],
		},

		{
			id: 'complaints',
			question: 'What do you find yourself saying about your sports bra?',
			description: "We know they're all a pain but choose the one that bugs you the most.",
			step: 1,
			error: 'Please select an option.',
			dependent: 'training',
			answers: [
				{
					id: 'training_running',
					label: '',
					inputType: 'list',
					options: [
						{
							id: 'training_running_1',
							label: "I hate when I don't feel a compressive support",
							result: BraQuizResult.HGArmourHigh,
						},
						{
							id: 'training_running_2',
							label: 'I hate when my bra is super sweaty',
							result: BraQuizResult.InfinityHigh,
						},
						{
							id: 'training_running_3',
							label: 'I hate when my bra is hard to adjust',
							result: BraQuizResult.HGArmourHigh,
						},
						{
							id: 'training_running_4',
							label: "I hate when it's hard to take my bra on and off",
							result: BraQuizResult.InfinityHigh,
						},
					],
				},
				{
					id: 'training_cycling',
					label: '',
					inputType: 'list',
					options: [
						{
							id: 'training_cycling_1',
							label: "I hate when my bra cups don't fit",
							result: BraQuizResult.SmartformMid,
						},
						{
							id: 'training_cycling_2',
							label: "I hate when it's hard to take my bra on and off",
							result: BraQuizResult.CrossbackMid,
						},
						{
							id: 'training_cycling_3',
							label: 'I hate when my bra gets super sweaty',
							result: BraQuizResult.InfinityMid,
						},
						{
							id: 'training_cycling_4',
							label: 'I hate when my bra has padding',
							result: BraQuizResult.CrossbackMid,
						},
					],
				},
				{
					id: 'training_yoga',
					label: '',
					inputType: 'list',
					options: [
						{
							id: 'training_yoga_1',
							label: 'I hate when my bra digs in',
							result: BraQuizResult.SeamlessLow,
						},
						{
							id: 'training_yoga_2',
							label: 'I hate when my bra gets really sweaty',
							result: BraQuizResult.InfinityLow,
						},
						{
							id: 'training_yoga_3',
							label: 'I hate when my bra has pads',
							result: BraQuizResult.SeamlessLow,
						},
						{
							id: 'training_yoga_4',
							label: 'I hate when my pads move around',
							result: BraQuizResult.InfinityLow,
						},
					],
				},
			],
		},
		{
			id: 'size',
			question: "What's your everyday bra size?",
			description: '',
			step: 2,
			error: 'Please select an option.',
			answers: [
				{
					id: 'size_band',
					label: 'Band Size',
					inputType: 'list',
					options: [
						{ id: 'size_band_30', label: '30' },
						{ id: 'size_band_32', label: '32' },
						{ id: 'size_band_34', label: '34' },
						{ id: 'size_band_36', label: '36' },
						{ id: 'size_band_38', label: '38' },
						{ id: 'size_band_40', label: '40' },
						{ id: 'size_band_42', label: '42' },
						{ id: 'size_band_44', label: '44' },
					],
				},
				{
					id: 'size_cup',
					label: 'Cup Size',
					inputType: 'list',
					options: [
						{ id: 'size_cup_aa', label: 'AA' },
						{ id: 'size_cup_a', label: 'A' },
						{ id: 'size_cup_b', label: 'B' },
						{ id: 'size_cup_c', label: 'C' },
						{ id: 'size_cup_d', label: 'D' },
						{ id: 'size_cup_dd', label: 'DD' },
						{ id: 'size_cup_ddd', label: 'DDD' },
					],
				},
			],
		},
	],
}
