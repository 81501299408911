import { Suspense, useCallback, useMemo, useState } from 'react'
import { useAuthModals } from '~/components/hooks/useAuthModals'
import { useEmailSubscribe } from '~/components/hooks/useEmailSubscribe'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { LoadingDots } from '~/components/primitives/LoadingDots/LoadingDots'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import { EnrollmentSource } from '~/lib/types/loyalty.interface'
import { LoyaltyEmailSubscriberSourceValues } from '~/lib/client-server/enrollment-helpers'
import logger from '~/lib/logger'
import emailPreferences from '~/lib/preferences/email'
import styles from '~/components/pages/Landing/Rewards/RewardsEnrollerShared.module.scss'
import { Button } from '@ua-digital-commerce/ua-web-components/components/Button'
import { useLoyalty } from '~/components/providers/LoyaltyProvider/LoyaltyProvider'
import { useSearchParams } from 'next/navigation'

const AuthenticationGuest = () => {
	const formatMessage = useFormatMessage()
	const { showLoginModal, showRegisterModal } = useAuthModals()

	return (
		<>
			<div className={styles.callout}>{formatMessage('a-new-way-to-score-more')}</div>
			<div className={styles.header}>{formatMessage('introducing-ua-rewards')}</div>
			<div className={styles.copy}>{formatMessage('rewards-landing-copy')}</div>
			<div className={styles['action-buttons']}>
				<Button
					type="button"
					data-testid="create-account-button"
					onClick={() => showRegisterModal(() => true, EnrollmentSource.ECOMMREWARDSLANDER)}
				>
					{formatMessage('join-and-create-account')}
				</Button>
				<span className={styles['authentication-login']}>
					{formatMessage('register-create-account', {
						registerLink: (
							<Button
								type="button"
								text
								onClick={() => showLoginModal(() => true, EnrollmentSource.ECOMMREWARDSLANDER)}
							>
								{formatMessage('login')}
							</Button>
						),
					})}
				</span>
			</div>
		</>
	)
}

const AuthenticationRegisteredNoneLoyalty = () => {
	const formatMessage = useFormatMessage()
	const [error, setError] = useState<string | null>(null)
	const { enroll, loyaltyOperationLoading: enrollLoyaltyLoading } = useLoyalty()
	const { addSubscriber } = useEmailSubscribe(
		LoyaltyEmailSubscriberSourceValues.ECOMMREWARDSLANDER,
		emailPreferences.emailSignupLocations.newVisitorModal,
	)
	const readonlySearchParams = useSearchParams()
	const { user } = useSession()

	const registeredUser = user?.isRegistered ? user : null
	const handleEnrollment = useCallback(async () => {
		const { success } = await enroll({ readonlySearchParams, source: EnrollmentSource.ECOMMREWARDSLANDER })

		if (!success) {
			setError(formatMessage('order-confirm-rewards-join-error'))
		}

		if (registeredUser?.profile?.email) {
			const result = await addSubscriber(
				registeredUser?.profile?.email,
				LoyaltyEmailSubscriberSourceValues.ECOMMREWARDSLANDER,
			)
			if (result?.errors) {
				logger.error('[Registration] Subscribing during registration failed')
			}
		}
	}, [readonlySearchParams, registeredUser?.profile?.email, addSubscriber, enroll, formatMessage])

	return (
		<>
			<div className={styles.callout}>{formatMessage('a-new-way-to-score-more')}</div>
			<div className={styles.header}>{formatMessage('introducing-ua-rewards')}</div>
			<div className={styles.copy}>{formatMessage('rewards-landing-copy')}</div>
			<div className={styles['action-buttons']}>
				<Button type="button" onClick={handleEnrollment} disabled={enrollLoyaltyLoading}>
					{enrollLoyaltyLoading ? <LoadingDots /> : formatMessage('rewards-join')}
				</Button>
				{error && <div className={styles.error}>{error}</div>}
				<p className={styles['authentication-rewards-legal']}>
					{formatMessage('login-disclaimer-loyalty', {
						rewardsTermsAndConditions: (
							<a href="https://help.underarmour.com/s/article/UA-Rewards-Terms-and-Conditions">
								{formatMessage('ua-rewards-terms-and-conditions')}
							</a>
						),
						termsOfUseLink: (
							<a target="_blank" rel="noreferrer" href={formatMessage('terms-and-conditions-href')}>
								{formatMessage('terms-and-conditions-text')}
							</a>
						),
						privacyPolicyLink: (
							<a target="_blank" rel="noreferrer" href={formatMessage('privacy-policy-href')}>
								{formatMessage('privacy-policy-text')}
							</a>
						),
					})}
				</p>
			</div>
		</>
	)
}

const AuthenticationRegisteredLoyalty = () => {
	const formatMessage = useFormatMessage()

	return (
		<>
			<div className={styles.callout}>{formatMessage('welcome-to-rewards')}</div>
			<div className={styles.header}>{formatMessage('profile-title-new')}</div>
			<div className={styles.copy}>{formatMessage('enroll-loyalty-success-message')}</div>
			<div className={styles['action-buttons']}>
				<LocaleLink href={'/my-rewards-locker'}>
					<Button type="button">{formatMessage('order-confirm-rewards-link')}</Button>
				</LocaleLink>
			</div>
		</>
	)
}

export const RewardsEnrollerAuthentication = () => {
	const { user } = useSession()
	const AuthenticationFlow = useMemo(() => {
		if (user?.isRegistered && !user.isLoyalty)
			return (
				<Suspense>
					<AuthenticationRegisteredNoneLoyalty />
				</Suspense>
			)
		if (user?.isLoyalty) return <AuthenticationRegisteredLoyalty />

		return <AuthenticationGuest />
	}, [user])

	return <>{AuthenticationFlow}</>
}
